import React, { useState } from 'react';
import './App.css';
import logo1 from './images/lords.png';
import logo2 from './images/pesco.png';
import logo3 from './images/grug.png';
import backgroundImage from './assets/icon.png';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setErrorMessage(''); 
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!inputValue.startsWith('0x')) {
      setErrorMessage('Input must be a valid wallet address');
      return; 
    }

    try {
      const response = await fetch('https://basedcartel.life/api/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: inputValue }),
      });

      if (response.ok) {
        setErrorMessage('Successfully submitted !');
        setInputValue(''); 
      } else {
        alert('Failed to submit input.');
      }
    } catch (error) {
      console.error('Error submitting input:', error);
    }
  };

  return (
    <div className="app-container"
      style={{
        backgroundImage: `url(${backgroundImage})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh', 
      }}
    >
      <header>
        <h1>Based Cartel Allowlist</h1>
      </header>
      <main>
        <form className="input-form" onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your wallet address"
            value={inputValue}
            onChange={handleInputChange} 
          />
          <button type="submit">Submit</button>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <p className="collaboration-text">In Collaboration With</p>

        <div className="collaboration-images">
          <img src={logo1} alt="Logo 1" />
          <img src={logo2} alt="Logo 2" />
          <img src={logo3} alt="Logo 3" />
        </div>
      </main>
    </div>
  );
}

export default App;
